function ProgressBar(props: { progress: number }) {
  const progress = /iPhone|Android/i.test(window.navigator.userAgent)
    ? Math.round((340 / 12) * props.progress)
    : Math.round((500 / 12) * props.progress);
  return (
    <div className='absolute font-sans sm:w-[340px] md:left-[205px] md:w-[500px]'>
      <p className='absolute top-5 h-2 rounded-r-lg bg-gray-200 sm:w-full md:left-[-50px] md:w-[480px]'></p>
      <p
        className='absolute top-5 h-2 rounded-l-lg bg-blue-800 md:left-[-75px]'
        style={{ width: String(progress) + "px" }}
      ></p>
      <p className='absolute top-0 h-2 text-[10px] text-blue-800 sm:left-[150px] md:text-center'>
        {props.progress}/12項目
      </p>
    </div>
  );
}

export default ProgressBar;
