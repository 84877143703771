import { useState } from "react";

import { useRecoilState } from "recoil";

import useMediaQuery from "@/hooks/useMediaQuery";
import { userSelector } from "@/stores/selectors/userSelector";
import "react-circular-progressbar/dist/styles.css";

type EditModalProps = {
  setIsEditModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isCompleteModal?: boolean;
};

function MyProfile(props: EditModalProps) {
  const isSm = useMediaQuery();
  const [user] = useRecoilState(userSelector);
  const [isDisplayProgress, setIsDisplayProgress] = useState(false);
  const percentage = 66;

  return (
    <div className='w-fulll mb-8'>
      <div className={`${props.isCompleteModal ? "mb-2 md:flex" : "mb-6 md:flex"}`}>
        <div
          className={`flex flex-col items-center justify-center rounded-lg border bg-white sm:w-full ${
            props.isCompleteModal ? "mr-2 mt-6 p-6 md:w-full" : "mr-6 h-[150px] p-8 md:w-2/6"
          }`}
        >
          <div className='text-center'>
            <div className='flex min-w-[130px] items-center justify-center text-[20px]'>
              <p>
                {user.last_name} {user.first_name}
              </p>
              {!props.isCompleteModal && (
                <img
                  src='images/pen.svg'
                  alt='#'
                  className='ml-2 h-6 w-6 cursor-pointer rounded-md bg-gray-200 p-1 hover:bg-gray-100'
                  onClick={() => {
                    if (props.setIsEditModalOpen && !props.isCompleteModal) {
                      props.setIsEditModalOpen(true);
                    }
                  }}
                />
              )}
            </div>

            {/* <div className='flex text-[13px] text-gray-500'>
              {"担当：佐川 涼介"}&emsp;{"000-0000-0000"}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
