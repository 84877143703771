import { useRecoilState } from "recoil";

import Carousel from "@/components/layouts/top/Carousel";
import useMediaQuery from "@/hooks/useMediaQuery";
import { userSelector } from "@/stores/selectors/userSelector";

const UpperContent = () => {
  const [user] = useRecoilState(userSelector);
  const isSm = useMediaQuery();
  const colorStyle = {
    background: `linear-gradient(to right, #2c3c6c, #405494)`,
  };

  return (
    <div className='z-1 relative top-[-10px] w-full overflow-x-hidden overflow-y-hidden sm:mt-1 sm:h-[695px] md:top-0 md:mt-0 md:h-[650px]'>
      <div className='absolute z-[-1] w-screen sm:h-[700px] md:h-[650px]'>
        <Carousel />
      </div>
      <div className=' mt-20 text-[#2c3c6c] sm:w-full sm:p-8 md:ml-20 md:mt-40   md:w-1/2 md:p-16'>
        <div className='font-bold sm:text-[30px] md:text-[50px]'>
          <p>成長企業と共に</p>
          <p>新たな挑戦を</p>
        </div>
        <div className='text-[13px] font-bold text-gray-700 sm:pt-4 sm:text-[16px] md:pt-4 md:text-[20px]'>
          <p>これまで培ってきた経験を活かして</p>
          <p>成長企業と共に新たな挑戦をしませんか？</p>
        </div>
        {user.first_name === "" && (
          <a
            href='/register'
            className='mb-2 mt-8 h-[50px] rounded-full py-4 pl-20 text-center font-bold text-gray-200 hover:text-gray-500 focus:outline-none sm:block sm:w-full sm:pl-20 sm:text-[14px] md:h-[53px] md:w-[360px] md:pl-24 md:text-[16px]'
            style={colorStyle}
          >
            新規会員登録はこちら
            {isSm ? <span>&emsp;&emsp;&emsp;&emsp;●</span> : <span>&emsp;&emsp;&emsp;&emsp;●</span>}
          </a>
        )}
        <div className='sm:text-center md:w-[360px] md:text-center'>
          {user.first_name === "" && (
            <a href='/login' className='text-[12px] font-bold text-gray-700'>
              すでにアカウントをお持ちの方は
              <span className='border-b border-gray-700  hover:text-gray-900'>ログイン</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpperContent;
