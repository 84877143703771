import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useRecoilState } from "recoil";

import AnnualIncome from "@/components/layouts/profile/AnnualIncome";
import CurrentCompany from "@/components/layouts/profile/CurrentCompany";
import CurrentIndustry from "@/components/layouts/profile/CurrentIndustry";
import CurrentJob from "@/components/layouts/profile/CurrentJob";
import EditAnnualIncome from "@/components/layouts/profile/EditAnnualIncome";
import EditCurrentCompany from "@/components/layouts/profile/EditCurrentCompany";
import EditCurrentIndustry from "@/components/layouts/profile/EditCurrentIndustry";
import EditCurrentJob from "@/components/layouts/profile/EditCurrentJob";
import EditLastEducation from "@/components/layouts/profile/EditLastEducation";
import EditModal from "@/components/layouts/profile/EditModal";
import FinalEducation from "@/components/layouts/profile/FinalEducation";
import JobList from "@/components/layouts/profile/JobList";
import MyProfile from "@/components/layouts/profile/MyProfile";
import RegistrationCompleteModal from "@/components/layouts/profile/RegistrationCompleteModal";
import SideMenue from "@/components/layouts/profile/SideMenue";
import TabLayout from "@/components/layouts/profile/TabLayout";
import useMediaQuery from "@/hooks/useMediaQuery";
import useScroll from "@/hooks/useScroll";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { ApiResponse, apiGet } from "@/utils/api";

function ProfilePage() {
  const [searchParams] = useSearchParams();

  const [user, setUser] = useRecoilState(userSelector);
  const [key] = useRecoilState(locationState);
  const scroll = useScroll();
  const isSm = useMediaQuery();
  const [isFixed, setIsFixed] = useState(false);
  const [isAddAnnualIncome, setIsAddAnnualIncome] = useState(false);
  const [isAddLastEducation, setIsAddLastEducation] = useState(false);
  const [isAddCurrentIndustry, setIsAddAddCurrentIndustry] = useState(false);
  const [isAddCurrentJob, setIsAddAddCurrentJob] = useState(false);
  const [isAddCurrentCompany, setIsAddCurrentCompany] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(key.component);

  useEffect(() => {
    setActiveTab(key.component);
    const modalQuery = searchParams.get("modal");
    if (modalQuery) {
      setIsCompleteModalOpen(true);
    }
  }, [key.component]);

  useEffect(() => {
    if (!isSm && scroll > 638) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, [scroll]);

  const checkUser = async () => {
    const res: ApiResponse<User> = await apiGet<User>("/api/user/me");
    if ("data" in res && res.success) {
      setUser(res.data);
      return;
    }
  };

  return (
    <div className='overflow-x-hidden font-sans'>
      {isEditModalOpen && <div className='bg-black fixed left-0 top-0 h-screen w-screen bg-opacity-10' />}
      {isEditModalOpen && (
        <EditModal setIsEditModalOpen={setIsEditModalOpen} setIsCompleteModalOpen={setIsCompleteModalOpen} />
      )}
      {isCompleteModalOpen && <RegistrationCompleteModal setIsCompleteModalOpen={setIsCompleteModalOpen} />}
      <div className='flex justify-center bg-blue-100'>
        <div className='pt-[40px] sm:w-screen md:w-[1000px]'>
          <MyProfile setIsEditModalOpen={setIsEditModalOpen} />
          <TabLayout setActiveTab={setActiveTab} />
        </div>
      </div>
      {activeTab === "profile" ? (
        <div className='flex justify-center bg-white'>
          <div className='mt-8 flex md:w-[1000px]'>
            <div className={`${isFixed ? "fixed top-5 " : "relative"}`}>
              <SideMenue />
            </div>
            <div className='overflow-x-hidden px-8' style={{ marginLeft: isFixed ? "175px" : "0" }}>
              <div className='mb-8 border-b pb-8 sm:w-screen sm:px-4 md:w-full' id='anual-income'>
                <div className='mb-4 flex items-center justify-between'>
                  <h2 className='flex-grow-0 text-[20px]'>現在の年収</h2>
                  {!isAddAnnualIncome && (
                    <button
                      onClick={() => setIsAddAnnualIncome(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddAnnualIncome ? (
                  <EditAnnualIncome
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAnnualIncome(false);
                    }}
                    cancel={() => {
                      setIsAddAnnualIncome(false);
                    }}
                    isProfile={true}
                  />
                ) : (
                  <AnnualIncome />
                )}
              </div>
              <div className='mb-8 border-b pb-8 sm:w-screen sm:px-4 md:w-full' id='last-education'>
                <div className='mb-4 flex items-center justify-between'>
                  <h2 className='flex-grow-0 text-[20px]'>最終学歴</h2>
                  {!isAddLastEducation && (
                    <button
                      onClick={() => setIsAddLastEducation(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddLastEducation ? (
                  <EditLastEducation
                    profileSubmit={() => {
                      checkUser();
                      setIsAddLastEducation(false);
                    }}
                    isProfile={true}
                    cancel={() => {
                      setIsAddLastEducation(false);
                    }}
                  />
                ) : (
                  <FinalEducation />
                )}
              </div>
              <div className='mb-8 border-b pb-8 sm:w-screen sm:px-4 md:w-full' id='current-industry'>
                <div className='mb-4 flex items-center justify-between'>
                  <h2 className='flex-grow-0 text-[20px]'>現在の業種</h2>
                  {!isAddCurrentIndustry && (
                    <button
                      onClick={() => setIsAddAddCurrentIndustry(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentIndustry ? (
                  <EditCurrentIndustry
                    isProfile={true}
                    user={user}
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAddCurrentIndustry(false);
                    }}
                    cancel={() => setIsAddAddCurrentIndustry(false)}
                  />
                ) : (
                  <CurrentIndustry />
                )}
              </div>
              <div className='mb-8 border-b pb-8 sm:w-screen sm:px-4 md:w-full' id='current-job'>
                <div className='mb-4 flex items-center justify-between'>
                  <h2 className='flex-grow-0 text-[20px]'>現在の職種</h2>
                  {!isAddCurrentJob && (
                    <button
                      onClick={() => setIsAddAddCurrentJob(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentJob ? (
                  <EditCurrentJob
                    isProfile={true}
                    user={user}
                    profileSubmit={() => {
                      checkUser();
                      setIsAddAddCurrentJob(false);
                    }}
                    cancel={() => setIsAddAddCurrentJob(false)}
                  />
                ) : (
                  <CurrentJob />
                )}
              </div>
              <div className='mb-8 border-b pb-8 sm:w-screen sm:px-4 md:w-full' id='current-company'>
                <div className='mb-4 flex items-center justify-between'>
                  <h2 className='flex-grow-0 text-[20px]'>現職企業名</h2>
                  {!isAddCurrentCompany && (
                    <button
                      onClick={() => setIsAddCurrentCompany(true)}
                      className='flex h-10 items-center rounded border border-blue-800 px-6 text-sm font-bold text-blue-800'
                    >
                      <h1>編集する</h1>
                    </button>
                  )}
                </div>
                {isAddCurrentCompany ? (
                  <EditCurrentCompany
                    setIsAddCurrentCompany={setIsAddCurrentCompany}
                    bgColor={"bg-blue-50"}
                    isProfile={true}
                  />
                ) : (
                  <CurrentCompany />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=''>
          <JobList />
        </div>
      )}
    </div>
  );
}

export default ProfilePage;
