import { useEffect } from "react";

import Apply from "@/components/layouts/jobs/Apply";
import { JobDetail } from "@/types/job";

function SideJob(props: { job: JobDetail }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='rounded-md bg-white sm:p-4 md:p-8'>
      {/* 働く条件 */}
      <div className='mb-16' id='1'>
        <p className='my-2 text-[18px] font-bold'>働く条件</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.job_type_name && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-1/6'>職種</p>
              <p className='md:w-5/6'>{props.job.job_type_name}</p>
            </div>
          )}
          <div className='mb-10 items-center md:flex'>
            {(props.job.salary_system ||
              props.job.annual_income ||
              props.job.reward_min ||
              props.job.salary_revision ||
              props.job.reward_max ||
              props.job.bonus ||
              props.job.reward_detail ||
              props.job.employment_status) && <p className='font-bold sm:mb-1 md:w-1/6'>報酬</p>}
            <div className='md:w-5/6'>
              {props.job.salary_system && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4' />
                  賃金形態：
                  <span dangerouslySetInnerHTML={{ __html: props.job.salary_system.replace(/\n/g, "<br />") }} />
                </span>
              )}
              <ul className='ml-4 list-inside list-disc'>
                {props.job.annual_income && <li>年収：{props.job.annual_income}</li>}
                {props.job.reward_min && props.job.reward_min !== 0 && props.job.reward_max !== 0 && (
                  <li>
                    基本給(時給)：{props.job.reward_min.toLocaleString()}円~{props.job.reward_max.toLocaleString()}円
                  </li>
                )}
                {props.job.salary_revision && <li>給与改定：{props.job.salary_revision}</li>}
                {props.job.bonus && <li>賞与：{props.job.bonus}</li>}
                <div className='ml-6'>
                  {props.job.reward_detail && (
                    <p>
                      - <span dangerouslySetInnerHTML={{ __html: props.job.reward_detail.replace(/\n/g, "<br />") }} />
                    </p>
                  )}
                  {props.job.employment_status && <p>- {props.job.employment_status}</p>}
                </div>
                <br />
              </ul>
            </div>
          </div>
          {props.job.working_hours && (
            <div className='mb-10 items-center md:flex'>
              <p className='font-bold sm:mb-1 md:w-1/6'>稼働時間</p>
              <p className='md:w-5/6'>{props.job.working_hours}</p>
            </div>
          )}

          <div className='items-center md:flex'>
            {(props.job.remote_work ||
              props.job.way_of_working ||
              props.job.holiday ||
              props.job.working_time ||
              props.job.treatment_benefits) && <p className='font-bold sm:mb-1 md:w-1/6'>働き方</p>}
            <div className='md:w-5/6'>
              {props.job.remote_work && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>リモートワーク：
                  {props.job.remote_work}
                </span>
              )}
              {props.job.way_of_working && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>働き方：
                  {props.job.way_of_working}
                </span>
              )}
              {props.job.holiday && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>休日：
                  <span dangerouslySetInnerHTML={{ __html: props.job.holiday.replace(/\n/g, "<br />") }} />
                </span>
              )}
              {props.job.working_time && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>稼働時間：
                  <span dangerouslySetInnerHTML={{ __html: props.job.working_time.replace(/\n/g, "<br />") }} />
                </span>
              )}
              {props.job.treatment_benefits && (
                <span className='flex'>
                  <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>待遇・福利厚生：
                  <span dangerouslySetInnerHTML={{ __html: props.job.treatment_benefits.replace(/\n/g, "<br />") }} />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 求めるスキル・経験 */}
      <div className='mb-16' id='2'>
        <p className='my-2 text-[18px] font-bold'>求めるスキル・経験</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.required_skills && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>必須スキル・経験</p>
              <ul className='ml-4 list-inside list-disc'>
                <span dangerouslySetInnerHTML={{ __html: props.job.required_skills.replace(/\n/g, "<br />") }} />
              </ul>
            </div>
          )}
          {props.job.welcome_skills && (
            <div>
              <p className='mb-4 font-bold'>歓迎するスキル・経験</p>
              <ul className='ml-4 list-inside list-disc'>
                <span dangerouslySetInnerHTML={{ __html: props.job.welcome_skills.replace(/\n/g, "<br />") }} />
              </ul>
            </div>
          )}
        </div>
      </div>
      {/* 業務内容 */}
      <div className='mb-16' id='3'>
        <p className='my-2 text-[18px] font-bold'>業務内容</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.business_content_summary && (
            <div className='mb-10'>
              <p className='mb-4 font-bold'>具体的な業務内容</p>
              <span dangerouslySetInnerHTML={{ __html: props.job.business_content_summary.replace(/\n/g, "<br />") }} />
            </div>
          )}
          {props.job.business_content_detail && (
            <div className='mb-6'>
              <p className='mb-4'>【業務詳細】</p>
              <span dangerouslySetInnerHTML={{ __html: props.job.business_content_detail.replace(/\n/g, "<br />") }} />
            </div>
          )}
        </div>
      </div>
      {/* 事業紹介 */}
      <div className='mb-16' id='4'>
        <p className='my-2 text-[18px] font-bold'>事業紹介</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          {props.job.business && (
            <div className='mb-10' dangerouslySetInnerHTML={{ __html: props.job.business.replace(/\n/g, "<br />") }} />
          )}
          {props.job.company_vision && (
            <div>
              <span className='mb-1 flex'>
                <img src='/images/stop.svg' className='mr-3 mt-1 h-4 w-4'></img>Vision
              </span>
              <div>
                <p>{props.job.company_vision}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 企業情報 */}
      <div id='5' className='mb-12'>
        <p className='my-2 text-[18px] font-bold'>企業情報</p>
        <div className='rounded-sm bg-blue-50 p-8'>
          <div>
            {props.job.company_name && <p className='mb-4'>{props.job.company_name}</p>}
            {props.job.company_url && <p>{props.job.company_url}</p>}
          </div>
        </div>
      </div>

      <Apply offer_id={props.job.id} title={props.job.title} is_applied={props.job.is_applied} />
    </div>
  );
}

export default SideJob;
