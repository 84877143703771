import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useMediaQuery from "@/hooks/useMediaQuery";

const PhotoSlider = () => {
  const isSm = useMediaQuery();
  const settings_1 = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: true,
    centerPadding: "150px",
    arrows: false,
  };
  const settings_2 = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: true,
    rtl: true,
    centerPadding: "75px",
    arrows: false,
  };
  const settings_3 = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 4,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: true,
    centerPadding: "0px",
    arrows: false,
  };
  const settings_1_sm = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 3,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: false,
    centerPadding: "0px",
    arrows: false,
  };
  const settings_2_sm = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 3,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: false,
    rtl: true,
    centerPadding: "0px",
    arrows: false,
  };
  const settings_3_sm = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 3,
    autoplaySpeed: 0,
    centerMode: true,
    vertical: false,
    centerPadding: "0px",
    arrows: false,
  };

  const photos_1 = [
    "/images/kv_pht0101.jpg",
    "/images/kv_pht0102.jpg",
    "/images/kv_pht0103.jpg",
    "/images/kv_pht0104.jpg",
    "/images/kv_pht0105.jpg",
    "/images/kv_pht0106.jpg",
  ];
  const photos_2 = [
    "/images/kv_pht0201.jpg",
    "/images/kv_pht0202.jpg",
    "/images/kv_pht0203.jpg",
    "/images/kv_pht0204.jpg",
    "/images/kv_pht0205.jpg",
    "/images/kv_pht0206.jpg",
  ];
  const photos_3 = [
    "/images/kv_pht0301.jpg",
    "/images/kv_pht0302.jpg",
    "/images/kv_pht0303.jpg",
    "/images/kv_pht0304.jpg",
    "/images/kv_pht0305.jpg",
    "/images/kv_pht0306.jpg",
  ];

  const gradientBackground = {
    background: "linear-gradient(to left, transparent, rgba(255, 255, 255,1), #ffffff)",
    width: "100%",
    height: "100%",
  };
  const gradientBackgroundSm = {
    background: "linear-gradient(to top, transparent, rgba(255, 255, 255,0.01), #ffffff)",
    width: "100%",
    height: "100%",
  };

  return (
    <div>
      {!isSm ? (
        <div>
          <div style={gradientBackground} className='absolute z-10'></div>
          <div className='flex w-full justify-end'>
            <Slider {...settings_1} className='w-[265px]'>
              {photos_1.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='w-[250px] object-cover' />
                </div>
              ))}
            </Slider>
            <Slider {...settings_2} className='w-[265px]'>
              {photos_2.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='w-[250px] object-cover' />
                </div>
              ))}
            </Slider>
            <Slider {...settings_3} className='w-[265px]'>
              {photos_3.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='w-[250px] object-cover' />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className='mt-[400px]'>
          <div style={gradientBackgroundSm} className='absolute z-10'></div>
          <div className='w-screen'>
            <Slider {...settings_1_sm} className='h-[100px]'>
              {photos_1.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='h-[90px] object-cover pr-2' />
                </div>
              ))}
            </Slider>
            <Slider {...settings_2_sm} className='h-[100px]'>
              {photos_2.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='h-[90px] object-cover pr-2' />
                </div>
              ))}
            </Slider>
            <Slider {...settings_3_sm} className='h-[100px]'>
              {photos_3.map((photo, index) => (
                <div key={index}>
                  <img src={photo} alt={`Slide ${index}`} className='h-[90px] object-cover pr-2' />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoSlider;
