import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";
import { JobList } from "@/types/job";

const JobListItem: FC<JobList> = (job: JobList) => {
  const navigate = useNavigate();
  const [key, setKey] = useRecoilState(locationState);

  const moveDetail = () => {
    setKey((prevValue) => ({ ...prevValue, path: "jobs/" + job.id, component: "detail" }));
    navigate(`/${key.tab ? "jobs" : "side_jobs"}/${job.id}`);
  };

  return (
    <div className='mb-12 h-fit w-[500px] rounded-[6px] bg-white md:mx-4'>
      <a onClick={moveDetail} className='cursor-pointer no-underline'>
        {job.company_image ? (
          <img src={job?.company_image} alt='' className='w-full rounded-t-[9px] sm:h-[250px] md:h-[200px]' />
        ) : (
          <img src='/images/ic_no_job.svg' className='new-image top-0 w-full sm:h-[250px] md:h-[200px]' />
        )}
        <div className='p-4 pb-3'>
          <div className='item-center flex justify-between'>
            <div className='mb-[8px] mr-[8px] flex w-fit items-center justify-center rounded-[999px] bg-blue-800 px-[12px] py-[2px] text-[12px] font-bold leading-5 text-white'>
              {job.job_type_name}
            </div>
          </div>

          <div className='h-[50px] overflow-x-hidden overflow-y-hidden py-[2px] text-[16px] font-bold'>{job.title}</div>
          <div className='h-[30px] py-[10px] text-[12px] text-gray-600'>
            <span className='num'>{job.reward}</span>
          </div>
        </div>
        <div className='mx-4 pb-4'>
          <p className='flex items-center'>
            {job.company_logo ? (
              <img src={job.company_logo as string} className='mr-[8px] h-[32px] w-[32px] rounded-[50%]' />
            ) : (
              <img src='/icon/ic_company.svg' className='mr-[8px] h-[32px] w-[32px] rounded-[50%] ' alt='' />
            )}
            <div className='string-truncate-single-line text-black text-[14px] leading-6'>{job.company_name}</div>
          </p>
        </div>
      </a>
    </div>
  );
};

export default JobListItem;
