import { useState } from "react";

import ProgressBar from "@/components/layouts/common/ProgressBar";
import EditCurrentCompany from "@/components/layouts/profile/EditCurrentCompany";

function CurrentCompany() {
  const [, setIsAddCurrentCompany] = useState(false);

  return (
    <div className='h-screen  bg-blue-100 font-sans'>
      <div className='flex flex-col'>
        <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2 pt-16'>
          <div className='text-black absolute flex justify-center rounded bg-white px-6 pt-8 sm:top-[88px] sm:h-[100px] sm:w-full md:top-[111px] md:h-[100px] md:w-[760px]'>
            <ProgressBar progress={10} />
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='mt-[60px] flex justify-center bg-white sm:w-full md:w-[760px]'>
            <EditCurrentCompany
              setIsAddCurrentCompany={setIsAddCurrentCompany}
              bgColor={"bg-white"}
              isProfile={false}
            ></EditCurrentCompany>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentCompany;
