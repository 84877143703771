import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function CompanyPage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0'>
      <img src='/images/company-top.png'></img>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-20 md:px-16 md:text-[16px]'>
        <ul className='font-bold '>
          <li className='mb-8 border-b border-gray-200 pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>社名</p>
            <p className='md:w-5/6'>カソーク株式会社</p>
          </li>
          <li className='mb-8 border-b border-gray-200 pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>代表</p>
            <p className='md:w-5/6'>関 彩</p>
          </li>
          <li className='mb-8 border-b border-gray-200 pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>事業内容</p>
            <p className='md:w-5/6'>副業／転職人材のマッチングプラットフォーム「Kasooku」</p>
          </li>
          <li className='mb-8 border-b border-gray-200 pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>設立日</p>
            <p className='md:w-5/6'>2022 年 10 月 31 日</p>
          </li>
          <li className='mb-8 border-b border-gray-200 pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>住所</p>
            <p className='md:w-5/6'>〒150-0002&emsp;東京都渋谷区渋谷 1-2-5&emsp;MFPR 渋谷ビル 10F</p>
          </li>
          <li className='mb-8pb-6 md:flex'>
            <p className='sm:pb-4 md:w-1/6'>グループ会社</p>
            <p className='md:w-5/6'>ユナイテッド株式会社 100%</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CompanyPage;
