import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useRecoilState } from "recoil";

import CareerChange from "@/components/layouts/jobs/CareerChange";
import CareerChangeTab from "@/components/layouts/jobs/CareerChangeTab";
import SideJob from "@/components/layouts/jobs/SideJob";
import SideJobTab from "@/components/layouts/jobs/SideJobTab";
import Top from "@/components/layouts/jobs/Top";
import useBrowserBackDetection from "@/hooks/useBlowserBackDetection";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { JobDetail } from "@/types/job";
import { apiGet } from "@/utils/api";

function JobsPage() {
  useBrowserBackDetection("/list", "");
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setActiveTab] = useState<number>(1);
  const { error } = useToast();
  const [job, setJob] = useState<JobDetail>({} as JobDetail);
  const [key] = useRecoilState(locationState);

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await apiGet("/api/offers/" + id);
      if ("data" in res) {
        setJob(res.data as JobDetail);
      } else if (res.code === 404) {
        error("求人詳細の取得に失敗しました。");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        error(res.message);
      }
    };
    fetchJobs();
  }, [key.tab]);

  return (
    <div className='bg-blue-100 py-8 font-sans md:px-24'>
      <Top job={job} />
      {key.tab === "side-job" ? (
        <div>
          <SideJobTab setActiveTab={setActiveTab} /> <SideJob job={job} />
        </div>
      ) : (
        <div>
          <CareerChangeTab setActiveTab={setActiveTab} /> <CareerChange job={job} />
        </div>
      )}
    </div>
  );
}

export default JobsPage;
