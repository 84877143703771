import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import EditButtonGroup from "../parts/EditButtonGroup";

import Loading from "@/components/layouts/common/Loading";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { masterState } from "@/stores/atoms/masterAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { industry, industryMainCategory } from "@/types/industry";
import { User } from "@/types/user";
import { ApiResponse, apiGet, apiPatch } from "@/utils/api";
import { formatIndustries } from "@/utils/formatIndustry";

interface CurrentIndustry {
  [key: string]: string | number;
}

interface Props {
  user?: User;
  profileSubmit?: () => void;
  cancel?: () => void;
  isProfile: boolean;
}

export const EditCurrentIndustry = (props: Props) => {
  useControlBlowserBack();
  const isSm = useMediaQuery();
  const { user, isProfile, profileSubmit, cancel } = props;
  const navigate = useNavigate();
  const { error } = useToast();
  const [, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  const [master, setMaster] = useRecoilState(masterState);
  const [industries, setIndustries] = useState<industryMainCategory[]>([]);
  // 選択しているmain Category
  const [selectedMainIndustry, setSelectedMainIndustry] = useState<number | null>(null);
  // 選択しているindustry
  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(null);

  const schema = yup.object({
    current_industry: yup.number().required("現在の業種は必須入力項目です"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{ current_industry: number }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      current_industry: user?.current_industry ?? undefined,
    },
  });
  const data = watch().current_industry;

  const onSubmit: SubmitHandler<CurrentIndustry> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 9;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
    if (res.code === 200 && !isProfile) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/current-job" }));
      navigate("/register/current-job");
      return;
    }
    if (res.code === 200 && profileSubmit) {
      setUser((prevState: User) => Object.assign({}, prevState, params));
      profileSubmit();
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  useEffect(() => {
    if (isProfile && user?.current_industry) {
      const mainIndustryId = formatIndustries(master.industries)?.find(
        (v) => v.id === user.current_industry,
      )?.mainCategoryId;
      setValue("current_industry", user.current_industry);
      setIndustries(master.industries ?? []);
      setSelectedMainIndustry(mainIndustryId ?? 0);
      setSelectedIndustry(user.current_industry);
    } else {
      const fetchIndustries = async () => {
        try {
          const res: ApiResponse<industryMainCategory[]> = await apiGet<industryMainCategory[]>("/api/industries");
          if (res.success) {
            if ("data" in res) {
              setIndustries(res.data);
              setMaster({ ...master, industries: res.data });
            }
          } else {
            console.error(res.message);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchIndustries();
    }
  }, [isProfile, user]);

  const handleOptionClick = (option: industry) => {
    setSelectedMainIndustry((prevSelected) => {
      return prevSelected === option.industry_id ? null : option.industry_id;
    });
  };

  const handleSetIndustryValue = (check: boolean, value: number) => {
    if (check) {
      setSelectedIndustry(value);
      setValue("current_industry", value);
    }
  };

  const handleReset = () => {
    setSelectedMainIndustry(null);
  };

  return (
    <div
      className={`${
        isProfile ? " rounded-[6px] bg-blue-50 p-[24px]" : "absolute sm:top-[110px] sm:w-4/5 md:top-[90px] md:w-[600px]"
      }`}
    >
      <Loading />
      <div className='flex-col gap-5'>
        <div className='mb-[10px] flex flex-col gap-1'>
          {!isProfile && (
            <div className='text-[14px] '>
              <label htmlFor='currentIndustry' className='font-bold'>
                現在の業種
              </label>
            </div>
          )}
          {!isSm ? (
            // PC用
            <div className='flex h-[350px] w-full border'>
              {/* 左側 */}
              <div className='h-full w-1/2 overflow-y-scroll  border-r'>
                <div className='p-4'>
                  <div className='space-y-2 text-[12px]'>
                    {industries.map((industry) => (
                      <button
                        key={industry.industry_category_id}
                        className={`flex w-full items-center px-4 py-2 ${
                          selectedMainIndustry === industry.industry_category_id ? "bg-blue-200" : ""
                        }`}
                        onClick={() =>
                          handleOptionClick({
                            industry_id: industry.industry_category_id,
                            industry_name: industry.industry_category_name,
                          })
                        }
                      >
                        <span>{industry.industry_category_name}</span>
                        {selectedMainIndustry === industry.industry_category_id && (
                          <img
                            src='/images/check-white.svg'
                            className='ml-auto h-4 w-4 rounded-xl bg-blue-700 p-0.5'
                          ></img>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              {/* 右側 */}
              <div className='h-full w-2/3 overflow-y-auto'>
                {selectedMainIndustry &&
                  industries
                    .find((v) => v.industry_category_id === selectedMainIndustry)
                    ?.industry_sub_categories.map((sub) => (
                      <div key={sub.industry_sub_category_id}>
                        <h3 className='mb-2 border-b-2 p-3 font-bold'>{sub.industry_sub_category_name}</h3>
                        <div className='flex flex-wrap p-4'>
                          {sub.industries.map((v) => (
                            <label
                              key={v.industry_id}
                              className='flex w-1/2 items-center space-x-2 pb-3 pl-2 pr-4 text-[13px]'
                            >
                              <input
                                type='checkbox'
                                className='form-checkbox'
                                checked={v.industry_id === selectedIndustry}
                                onChange={(e) => handleSetIndustryValue(e.target.checked, v.industry_id)}
                              />
                              <span>{v.industry_name}</span>
                            </label>
                          ))}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          ) : (
            // スマホ用
            <div className='h-[350px] w-full overflow-y-scroll rounded-md border p-2'>
              {industries.map((industry) => (
                <div key={industry.industry_category_id}>
                  <button
                    className={`flex w-full px-4 py-2 text-left  ${
                      selectedMainIndustry === industry.industry_category_id ? "bg-blue-300 " : ""
                    }`}
                    onClick={() =>
                      handleOptionClick({
                        industry_id: industry.industry_category_id,
                        industry_name: industry.industry_category_name,
                      })
                    }
                  >
                    <span>{industry.industry_category_name}</span>
                    <img src='/images/chevron-down.svg' className='ml-auto mt-1 h-3 w-3'></img>
                  </button>
                  {selectedMainIndustry === industry.industry_category_id && (
                    <div className='p-4'>
                      {industry.industry_sub_categories.map((subOption) =>
                        subOption.industries.map((v) => (
                          <label key={v.industry_id} className='flex space-x-2 pb-3 pl-2 pr-4 text-[13px]'>
                            <input
                              type='checkbox'
                              className='form-checkbox'
                              checked={v.industry_id === selectedIndustry}
                              onChange={(e) => handleSetIndustryValue(e.target.checked, v.industry_id)}
                            />
                            <span>{v.industry_name}</span>
                          </label>
                        )),
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <span className='p-1 text-xs text-red-500'>{errors?.current_industry?.message}</span>
        {/* {!isSm && (
          <button className='text-[13px] hover:text-gray-600' onClick={handleReset}>
            選択をすべて解除
          </button>
        )} */}
        {isProfile ? (
          <div className='flex items-center justify-end'>
            <EditButtonGroup submit={handleSubmit(onSubmit)} cancel={cancel} isSubmit={data !== 0} />
          </div>
        ) : (
          <div className='flex justify-center'>
            <button
              type='submit'
              className='my-10 w-3/4 rounded-full bg-blue-700 py-3 text-center text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCurrentIndustry;
