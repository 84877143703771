import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import Loading from "@/components/layouts/common/Loading";
import ProgressBar from "@/components/layouts/common/ProgressBar";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiPatch, apiPost } from "@/utils/api";

interface Birthday {
  [key: string]: Date | string | number;
}

function BirthdayPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);

  const schema = yup.object({
    birthday: yup
      .string()
      .required("生年月日は必須入力項目です")
      .test("valid-birthday", "無効な生年月日です", function (value) {
        if (!value) {
          return true;
        }
        if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          return false;
        }
        const parsedDate = new Date(value);
        const currentDate = new Date();
        if (parsedDate >= new Date("1900-01-01") && parsedDate <= currentDate) {
          return true;
        }

        return false;
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ birthday: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Birthday> = async (params) => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    params.register_step = 4;
    const formatDate = new Date(params.birthday);
    params.birthday = `${formatDate.getFullYear()}-${formatDate.getMonth() + 1}-${formatDate.getDate()}`;
    const res = await apiPatch("/api/register", params);
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));

    if (res.code === 200) {
      setKey((prevValue) => ({ ...prevValue, path: "/register/postal-code" }));
      navigate("/register/postal-code");
      return;
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };
  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <Loading />
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[320px] sm:w-[375px] md:top-[111px] md:h-[300px] md:w-[760px]'>
          <ProgressBar progress={6} />
          <div className='ext-[13px] absolute w-[336px] sm:top-[110px] md:top-[90px]'>
            <div className='mb-8 flex flex-col gap-1'>
              <label htmlFor='birthday' className='text-[14px] font-bold'>
                生年月日
              </label>
              <input
                min='1900-01-01'
                max='2100-12-31'
                type='date'
                className='h-10 w-36 rounded-md border border-gray-200 p-3'
                placeholder='年/月/日'
                {...register("birthday")}
              />
              <span className='p-1 text-xs text-red-500'>{errors.birthday?.message}</span>
            </div>
            <button
              type='submit'
              className='my-1 w-full rounded-full bg-blue-700 py-3 text-center text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthdayPage;
