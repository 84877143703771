import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

interface School {
  id: number;
  name: string;
}

function FinalEducation() {
  const [user] = useRecoilState(userSelector);
  const school = user.user_schools ? user.user_schools : null;
  return (
    <div>
      {school === null ? (
        <div className='text-[13px] sm:w-[full] md:flex md:w-[780px]'>
          <p className='text-[13px]'>最終学歴が入力されていません。</p>
        </div>
      ) : (
        <div className='flex  sm:w-[full] md:w-[780px]'>
          <div className='w-11/12' key={school.graduated_year}>
            <div className='flex text-gray-700'>
              <p className='pr-4 text-[16px]  font-bold'>{school.school_name}</p>
              {school.graduated_year && <p className='pr-4 text-[14px]'>{`${school.graduated_year}/03`}</p>}
            </div>
            <p className='bg-white text-[13px] text-gray-500'>{school.department}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinalEducation;
