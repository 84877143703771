import { useState } from "react";

import useMediaQuery from "@/hooks/useMediaQuery";

function FooterPage() {
  const items = [
    { title: "第1期 決算公告", url: "【第1期】カソーク_決算書.pdf" },
    { title: "第1期 個別注記", url: "【第1期】カソーク_個別注記表_202303.pdf" },
    { title: "第6期 ココドル決算公告", url: "【第6期】ココドル_決算書.pdf" },
    { title: "第6期 ココドル個別注記表", url: "【第6期】ココドル_個別注記表_202303.pdf" },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const isMd = useMediaQuery();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  return (
    <div className=' border-t-4 border-blue-900 bg-white font-sans sm:py-8 md:h-[400px] md:p-8 md:px-16'>
      <div className='text-[14px] md:flex'>
        <div className='sm:flex sm:w-full sm:justify-center md:w-1/3'>
          <img src='/images/logo.svg ' alt='logo'></img>
        </div>
        {!isMd ? (
          <div className='flex w-full justify-end  font-bold'>
            <div className='ml-8'>
              <a className='hover:text-gray-500' href='/company' target='_blank' rel='noopener noreferrer'>
                会社概要
              </a>
            </div>
            <div className='ml-8'>
              <a className='hover:text-gray-500' href='/term' target='_blank' rel='noopener noreferrer'>
                利用規約
              </a>
            </div>
            <div className='ml-8'>
              <a className='hover:text-gray-500' href='/privacy' target='_blank' rel='noopener noreferrer'>
                プライバシーポリシー
              </a>
            </div>
            <div className='ml-8'>
              <a className='hover:text-gray-500' href='/tokutei' target='_blank' rel='noopener noreferrer'>
                特定商取引法に基づく表示
              </a>
            </div>
            <div className='ml-8'>
              <a
                className='hover:text-gray-500'
                href='https://kasooku.jp/contacts/new'
                target='_blank'
                rel='noopener noreferrer'
              >
                お問い合わせ
              </a>
            </div>
          </div>
        ) : (
          <div className='mt-8 w-full justify-end text-[13px]  font-bold'>
            <div className='flex w-full border-y border-gray-300'>
              <div className='w-1/2 py-2.5 text-center'>
                <a className='' href='/'>
                  ホーム
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='/company' target='_blank' rel='noopener noreferrer'>
                  会社概要
                </a>
              </div>
            </div>
            <div className='flex w-full '>
              <div className='w-1/2 py-2.5 text-center'>
                {" "}
                <a className='' href='/term' target='_blank' rel='noopener noreferrer'>
                  利用規約
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='/privacy' target='_blank' rel='noopener noreferrer'>
                  プライバシーポリシー
                </a>
              </div>
            </div>
            <div className='flex w-full border-y border-gray-300'>
              <div className='w-1/2 py-2.5 text-center'>
                {" "}
                <a className='' href='/tokutei' target='_blank' rel='noopener noreferrer'>
                  特定商取引法に基づく表示
                </a>
              </div>
              <div className='w-1/2 border-l border-gray-300 py-2.5 text-center'>
                <a className='' href='https://kasooku.jp/contacts/new' target='_blank' rel='noopener noreferrer'>
                  お問い合わせ
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMd ? (
        <div className='my-8 w-full'>
          <div className='flex items-center'>
            <div className='w-1/12'>
              <button onClick={prevSlide} className='cursor-pointer rounded-full hover:bg-gray-100'>
                <img src='/images/arrow_left.svg' className='h-8 w-8' alt='left-arrow' />
              </button>
            </div>
            {items.map((item, index) => (
              <div key={index} className={`w-10/12  p-2 ${index === currentIndex ? "border-blue-500" : ""}`}>
                <a
                  href={"/pdf/" + item.url}
                  download={item.url}
                  className='w-full py-2.5 text-center hover:text-gray-500 '
                >
                  <p className='mx-auto flex min-h-[66px] w-full items-center border border-gray-300 p-2 text-[14px] font-semibold'>
                    <span className='w-3/4 '>{items[(index - currentIndex + items.length) % items.length].title}</span>
                    <span className='flex w-1/4 justify-end'>
                      <img src='/images/pdf.svg' className='h-[20px] w-[20px] text-red-500'></img>
                    </span>
                  </p>
                </a>
              </div>
            ))}
            <div className='w-1/12'>
              <button onClick={nextSlide} className='cursor-pointer rounded-full hover:bg-gray-100'>
                <img src='/images/arrow_right.svg' className='h-8 w-8' alt='right-arrow' />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='my-8 w-full'>
          <div className='flex items-center'>
            <div className='w-1/6'>
              <button onClick={prevSlide} className='cursor-pointer rounded-full hover:bg-gray-100'>
                <img src='/images/arrow_left.svg' className='h-8 w-8' alt='left-arrow' />
              </button>
            </div>
            <div className='flex overflow-x-hidden'>
              {items.map((item, index) => (
                <div key={index} className='w-1/2 p-2'>
                  <a href={"/pdf/" + item.url} download={item.url} className=' py-2.5 text-center hover:text-gray-500'>
                    <p className='mx-auto  flex min-h-[66px] w-[140px] items-center border border-gray-300 p-2 text-[13px] font-semibold'>
                      <span className='flex  w-3/4 text-center'>
                        {items[(index - currentIndex + items.length) % items.length].title}
                      </span>
                      <span className='mt-8 flex w-1/4 justify-end'>
                        <img src='/images/pdf.svg' className='h-[20px] w-[20px] text-red-500'></img>
                      </span>
                    </p>
                  </a>
                </div>
              ))}
            </div>

            <div className='w-1/6'>
              <button onClick={nextSlide} className='cursor-pointer rounded-full hover:bg-gray-100'>
                <img src='/images/arrow_right.svg' className='h-8 w-8' alt='right-arrow' />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='flex sm:text-[10px] md:text-[12px]'>
        <div className='w-3/4 sm:mb-6 sm:w-full sm:px-4  '>
          <p className='py-1'>
            2023年6月1日をもって、カソーク株式会社との吸収合併により株式会社ココドルは消滅しております。
          </p>
          <p className='py-1'>株式会社ココドルの決算公告については、以下のPDFをご確認ください。</p>
        </div>
        {!isMd && (
          <div className='flex w-1/4 items-end  justify-end italic'>
            <p>© kasooku inc. All right reserved.</p>
          </div>
        )}
      </div>
      {isMd && (
        <div className='flex w-full justify-center  text-[10px] italic'>
          <p>© kasooku inc. All right reserved.</p>
        </div>
      )}
    </div>
  );
}

export default FooterPage;
