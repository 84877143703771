import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function useBrowserBackDetection(key: string, component: string) {
  const [, setKey] = useRecoilState(locationState);
  window.addEventListener("popstate", () => {
    setKey((prevValue) => ({ ...prevValue, path: key, component: component }));
  });
}

export default useBrowserBackDetection;
