import { Link as Scroll } from "react-scroll";

function SideMenue() {
  return (
    <div className='rounded-lg border border-gray-200 sm:w-[0px] md:w-[174px]'>
      <ul className='text-[14px] text-base text-gray-700 '>
        <li className='mx-[16px] my-[16px]'>
          <Scroll to='anual-income' className='cursor-pointer hover:text-blue-500 active:text-blue-700'>
            現在の年収
          </Scroll>
        </li>
        <li className='mx-[16px] my-[16px]'>
          <Scroll to='last-education' className='cursor-pointer hover:text-blue-500 active:text-blue-700'>
            最終学歴
          </Scroll>
        </li>
        <li className='mx-[16px] my-[16px]'>
          <Scroll to='current-industry' className='cursor-pointer hover:text-blue-500 active:text-blue-700'>
            現在の業種
          </Scroll>
        </li>
        <li className='mx-[16px] my-[16px]'>
          <Scroll to='current-job' className='cursor-pointer hover:text-blue-500 active:text-blue-700'>
            現在の職種
          </Scroll>
        </li>
        <li className='mx-[16px] my-[16px]'>
          <Scroll to='current-company' className='cursor-pointer hover:text-blue-500 active:text-blue-700'>
            現職企業名
          </Scroll>
        </li>
      </ul>
    </div>
  );
}

export default SideMenue;
