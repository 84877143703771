import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiPost, apiGet } from "@/utils/api";

interface Login {
  [key: string]: string;
}

function LoginPage() {
  useControlBlowserBack();

  const navigate = useNavigate();
  const { error } = useToast();
  const [, setUser] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const schema = yup.object({
    email: yup
      .string()
      .required("メールアドレスは必須入力項目です")
      .email("有効なメールアドレスを入力してください")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "有効なメールアドレスを入力してください"),
    password: yup
      .string()
      .required("パスワードは必須入力項目です")
      .matches(/^[a-zA-Z0-9\d!?_+*'"`#$%&\-^\\@;:,./=~|[\](){}<>“‘]+$/, "有効なパスワードを入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Login> = async (params) => {
    await apiGet("/sanctum/csrf-cookie").then(async () => {
      const res = await apiPost("/api/login", params);
      if (res.code === 200) {
        setUser((prevState: User) => Object.assign({}, prevState, params));
        setKey((prevValue) => ({ ...prevValue, path: "" }));
        navigate("/");
      } else {
        error("ログインに失敗しました");
      }
    });
  };

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  return (
    <div className='flex min-h-screen flex-col bg-blue-100 font-sans'>
      <div className='container mx-auto flex max-w-md flex-1 flex-col items-center justify-center px-2'>
        <div className='text-black absolute flex justify-center rounded bg-white px-6 py-8 sm:top-[88px] sm:h-[500px] sm:w-full md:top-[111px] md:h-[500px] md:w-[760px]'>
          <div className='text-center'>
            <h2 className='mb-6 text-[20px] font-bold'>メールアドレスでログイン</h2>
            <p className='text-[14px]'>登録したメールアドレスを入力してください。</p>
          </div>
          <div className='absolute w-[336px] text-[13px] sm:top-[130px] md:top-[130px]'>
            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='email' className='text-[13px] font-bold'>
                  メールアドレス
                </label>
                <input
                  type='text'
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("email")}
                />
                <span className='absolute p-1 text-xs text-red-500'>{errors.email?.message}</span>
              </div>
            </div>
            <p className='mb-4 text-[12px] text-gray-500'>半角英数で入力してください</p>
            <div className='form-group mb-6'>
              <div className='mb-2 mr-1'>
                <label htmlFor='password' className='text-[13px] font-bold'>
                  パスワード
                </label>
                <input
                  type={isRevealPassword ? "text" : "password"}
                  className='border-grey-light block w-full rounded border p-3'
                  {...register("password")}
                />
                <span onClick={togglePassword} role='presentation' className='absolute right-[10px] top-[155px]'>
                  {isRevealPassword ? <img src='/images/eye.svg'></img> : <img src='/images/eye_slash.svg'></img>}
                </span>
                <span className='absolute p-1 text-xs text-red-500'>{errors.password?.message}</span>
              </div>
            </div>
            <p className='mb-4 text-[12px] text-gray-500'>半角英数字を含む8文字以上</p>

            <button
              type='submit'
              className='my-1 w-full  rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none'
              onClick={handleSubmit(onSubmit)}
            >
              次へ
            </button>
            <div className='mt-6 text-center'>
              <a href='/reminder/password' className='text-[14px] text-gray-700 hover:underline'>
                パスワードを忘れた方はこちら
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
