import { useRecoilState } from "recoil";

import { userSelector } from "@/stores/selectors/userSelector";

function AnnualIncome() {
  const [user] = useRecoilState(userSelector);
  const incomeArr = [
    { label: "400万未満", value: 1 },
    { label: "400万〜500万", value: 2 },
    { label: "500万〜600万", value: 3 },
    { label: "600万〜700万", value: 4 },
    { label: "700万〜800万", value: 5 },
    { label: "800万〜900万", value: 6 },
    { label: "900万〜1000万", value: 7 },
    { label: "1000万以上", value: 8 },
  ];
  return (
    <div>
      {!user.annual_income ? (
        <div className='text-[13px] sm:w-[full] md:flex md:w-[780px]'>
          <p className='text-[13px] '>現在の年収が入力されていません。</p>
        </div>
      ) : (
        <div className='flex  sm:w-[full] md:w-[780px]'>
          <div className='w-fill rounded-full bg-gray-100 px-[16px] py-[6px] text-[13px]'>
            {incomeArr.find((v) => v.value === user?.annual_income)?.label}
          </div>
        </div>
      )}
    </div>
  );
}

export default AnnualIncome;
