import axios, { AxiosError } from "axios";

const api = axios.create({
  responseType: "json",
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
});

export type SuccessResDataObjectType<T, U = unknown> = {
  data: T;
  success: boolean;
  code: number;
  message?: string;
  errors?: U;
};

export type FailedResErrorObjectType<U = unknown> = {
  success: boolean;
  code: number;
  message: string;
  errors?: U;
};

export type ApiResponse<T, U = unknown> = SuccessResDataObjectType<T, U> | FailedResErrorObjectType<U>;

async function syncApi<T, U>(
  method: "get" | "post" | "patch" | "delete",
  url: string,
  params = {},
): Promise<ApiResponse<T, U>> {
  try {
    const { data, status } = await api[method]<{ data: T }>(url, params);
    return {
      data: data.data,
      success: true,
      code: status,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      return {
        success: false,
        code: error.response?.status ?? 500,
        message: error.response?.data.message,
        errors: error.response?.data.errors as U,
      };
    } else {
      return {
        success: false,
        code: 500,
        message: "Internal Server Error",
      };
    }
  }
}

export async function apiGet<T, U = unknown>(url: string, params = {}) {
  return await syncApi<T, U>("get", `${process.env.REACT_APP_API_BASE_URL}` + url, params);
}

export async function apiPost<T, U = unknown>(url: string, params = {}) {
  return await syncApi<T, U>("post", `${process.env.REACT_APP_API_BASE_URL}` + url, params);
}

export async function apiPatch<T, U = unknown>(url: string, params = {}) {
  return await syncApi<T, U>("patch", `${process.env.REACT_APP_API_BASE_URL}` + url, params);
}

export async function apiDelete<T, U = unknown>(url: string, params = {}) {
  return await syncApi<T, U>("delete", `${process.env.REACT_APP_API_BASE_URL}` + url, { data: params });
}
