import { useEffect } from "react";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

function CompanyPage() {
  const [, setKey] = useRecoilState(locationState);

  useEffect(() => {
    setKey((prevValue) => ({ ...prevValue, component: "footer" }));
  }, []);

  return (
    <div className='overflow-x-hidden bg-blue-50 sm:mt-4 md:mt-0'>
      <div className='rounded-lg bg-white py-8 sm:mx-4 sm:my-10 sm:px-8 sm:text-[14px] md:mx-40 md:my-10 md:px-16 md:text-[16px]'>
        <p className='text-[12px] font-bold text-gray-500'>
          2023年1月23日制定 <br /> 2023年2月24日最終改定
          <br />
          <br />
        </p>
        <p className='text-[28px] font-bold'> プライバシーポリシー</p>
        東京都渋谷区渋谷一丁目２番５号
        <br /> カソーク株式会社
        <br />
        代表取締役社長 関 彩<br />
        <br />
        <p className='mb-4 border-b border-gray-200 pb-8'>
          カソーク株式会社（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。
        </p>
        <br />
        <p className='mb-4 text-[18px] font-bold'> 1. 個人情報の定義</p>
        本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義される個人情報を意味するものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 2. 個人情報の利用目的</p>
        当社は、個人情報を以下の目的で利用いたします。 <br />
        (1) 当社が運営するサービス（以下「当社サービス」といいます。）の提供のため <br />
        (2) 当社サービスに関するご案内、お問い合せ等への対応のため <br />
        (3) 当社の商品、サービス等のご案内のため <br />
        (4) 当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため{" "}
        <br />
        (5) 当社サービスに関する規約等の変更などを通知するため <br />
        (6) 当社サービスの改善、新サービスの開発等に役立てるため <br />
        (7) 当社サービスに関連して、個人を識別できない形式に加工した統計データを作成するため <br />
        (8) その他、「7.5共同利用」に記載した目的 <br />
        (9) その他、上記利用目的に付随する目的のため
        <p className='mb-4 mt-8 text-[18px] font-bold'> 3. 個人情報利用目的の変更</p>
        当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 4. 個人情報利用の制限</p>
        当社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
        <br />
        (1) 法令に基づく場合 <br />
        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき <br />
        (3)
        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
        <br />
        (4)
        国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        <p className='mb-4 mt-8 text-[18px] font-bold'> 5. 個人情報の適正な取得</p>
        5.1 当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
        <br /> 5.2
        当社は、次の場合を除き、あらかじめ本人の同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します。）を取得しません。
        <br />
        (1) 第4項各号のいずれかに該当する場合 <br />
        (2)
        当該要配慮個人情報が、本人、国の機関、地方公共団体、個人情報保護法第76条第1項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合
        <br />
        (3) 本人を目視し、又は撮影することにより、その外形上明らかな要配慮個人情報を取得する場合 <br />
        (4)
        第三者から要配慮個人情報の提供を受ける場合であって、当該第三者による当該提供が第7.1項各号のいずれかに該当するとき
        <br /> 5.3
        当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるところにより、次に掲げる事項の確認を行います。ただし、当該第三者による当該個人情報の提供が第4項各号のいずれかに該当する場合又は第7.1項各号のいずれかに該当する場合を除きます。
        <br />
        (1)
        当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者（法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人）の氏名）
        <br />
        (2) 当該第三者による当該個人情報の取得の経緯
        <p className='mb-4 mt-8 text-[18px] font-bold'> 6. 個人情報の安全管理</p>
        当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 7. 第三者提供 7.1</p>
        当社は、第4項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。
        <br />
        (1)
        利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
        <br />
        (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合 <br />
        (3) 個人情報保護法の定めに基づき共同利用する場合
        <br /> 7.2
        第7.1項の定めにかかわらず、当社は、第4項各号のいずれかに該当する場合を除くほか、外国（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される国を除きます。）にある第三者（個人情報保護法第24条に基づき個人情報保護委員会規則で指定される基準に適合する体制を整備している者を除きます。）に個人情報を提供する場合には、あらかじめ外国にある第三者への提供を認める旨の本人の同意を得るものとします。
        <br /> 7.3 当社は、個人情報を第三者に提供したときは、個人情報保護法第25条に従い、記録の作成及び保存を行います。
        <br /> 7.4
        当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護法第26条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
        <br /> 7.5 共同利用 当社は、以下のとおり、個人情報を共同利用いたします。 <br />
        (1) 共同して利用する者の範囲 当社グループ会社（当社のグループ会社はこちらをご参照ください） <br />
        (2) 共同して利用する者の利用目的
        <br /> ① 商品・サービスの提供のため ②
        商品・サービスの提供を含む契約の締結・履行、契約後の管理を当社グループ全体で適切に行うため
        <br /> ③ 当社グループとお取引先様との契約の締結・履行、契約後の管理、その他お取引先様への必要な連絡のため
        <br />④ ダイレクトメールの発送、商品・サービスに関するご案内、アンケート等実施のため
        <br /> ⑤ 販売分析その他調査・研究および新たな商品・サービス等の開発のため
        <br /> ⑥ 商品・サービスに関するお問い合わせ・ご要望・ご意見及びサポート対応のため
        <br /> ⑦ 採用選考の結果採用となった方の応募情報について、入社後に当社グループの人事情報とするため
        <br /> ⑧ 当社グループ就職希望者・予定者に対する企業情報の提供および必要な連絡のため <br />⑨
        当社グループの経営管理・内部管理のため <br />
        (3) 共同して利用される個人情報の項目 <br />①
        氏名、通称、お客様番号、各種ID（お客様ID等）、年齢、生年月日、性別、家族情報 <br />②
        ソーシャル・ネットワーキング・サービス上の情報（ID、アカウント、プロファイル、投稿等）
        <br /> ③ ご連絡先情報（住所、電話番号、ファックス番号、メールアドレス、写真、年齢、資格、略歴等）
        <br /> ④ 勤務先情報（会社名、代表者名、所属部署名、役職、住所、電話番号、ファックス番号、メールアドレス等）
        <br /> ⑤
        口座番号、クレジット／デビットカード等の支払手段の詳細を含む決済情報、クレジットカード番号、クレジットカード有効期限、クレジットカード利用履歴及び関連情報
        <br />⑥ お問い合わせ・ご要望・ご意見の内容、商品・サービス等の購入履歴、お客様とのやりとりの中に含まれる情報、{" "}
        <br />⑦
        クッキーやウェブサイト上の行動ログ等を含むお客様が当社グループウェブサイトやモバイルアプリをどのようにご利用になったかについての情報等
        <br />⑧ 求職情報（資格、経歴、希望条件等） <br />
        (4) 個人情報の管理について責任を有する者 ユナイテッド株式会社 住所：〒150-0002 東京都渋谷区渋谷1-2-5
        MFPR渋谷ビル B1F（受付）・10F 代表者：代表取締役社長兼執行役員 早川 与規
        <p className='mb-4 mt-8 text-[18px] font-bold'> 8. 個人情報の開示</p>
        当社は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり10,000円）を頂戴しておりますので、あらかじめ御了承ください。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 9. 個人情報の訂正等</p>
        当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 10. 個人情報の利用停止等</p>
        当社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、又は個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 11. 匿名加工情報の取扱い</p>
        11.1
        当社は、匿名加工情報（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成するときは、個人情報保護委員会規則で定める基準に従い、個人情報を加工するものとします。
        <br /> 11.2
        当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定める基準に従い、安全管理のための措置を講じます。
        <br />
        11.3
        当社は、匿名加工情報を作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。
        <br /> 11.4
        当社は、匿名加工情報（当社が作成したもの及び第三者から提供を受けたものを含みます。以下別段の定めがない限り同様とします。）を第三者に提供するときは、個人情報保護委員会規則で定めるところにより、あらかじめ、
        第三者に提供される匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法について公表するとともに、当該第三者に対して、当該提供に係る情報が匿名加工情報である旨を明示します。
        <br /> 11.5
        当社は、匿名加工情報を取り扱うに当たっては、匿名加工情報の作成に用いられた個人情報に係る本人を識別するために、
        <br />
        (1)匿名加工情報を他の情報と照合すること、及び
        <br />
        (2)当該個人情報から削除された記述等若しくは個人識別符号又は個人情報保護法第36条第1項の規定により行われた加工の方法に関する情報を取得すること（(2)は第三者から提供を受けた当該匿名加工情報についてのみ）を行わないものとします。
        <br /> 11.6
        当社は、匿名加工情報の安全管理のために必要かつ適切な措置、匿名加工情報の作成その他の取扱いに関する苦情の処理その他の匿名加工情報の適正な取扱いを確保するために
        必要な措置を自ら講じ、かつ、当該措置の内容を公表するよう努めるものとします。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 12. Cookie（クッキー）その他の技術の利用</p>
        当社のサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社のサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社のサービスの一部の機能をご利用いただけなくなる場合があります。詳細についてはクッキーポリシーをご確認ください。
        <p className='mb-4 mt-8 text-[18px] font-bold'> 13. お問い合わせ</p>
        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
        <br />
        <br />
        〒150-0002 東京都渋谷区渋谷1-2-5 MFPR渋谷ビル10F
        <br /> カソーク株式会社 プライバシーポリシー担当
        <br />
        E-mail：info@kasooku.jp
        <p className='mb-4 mt-8 text-[18px] font-bold'> 14. 継続的改善</p>
        当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
        <br />
        <p className='mt-8 text-[28px] font-bold'> クッキーポリシー </p>
        <p className='mb-4 mt-4 text-[18px] font-bold'> クッキーとは</p>
        クッキーはWebサイトからご利用者様の端末に記録目的で送信される小さなテキストファイルです。クッキーはご利用者様の端末、通常Webブラウザを識別しますが、それ自体でご利用者様を個人として識別することはありません。
        <p className='mb-4 mt-4 text-[18px] font-bold'> クッキーの利用目的</p>
        クッキーはご利用者様に最適な状態でWebサイトを利用いただくために必要であり、使用している言語などやWebサイトにおける行動などを記録する際に利用します。
        また、機能を向上させる、パフォーマンスを改善するために分析データを収集する、インターネットにおいてご利用者様をトラッキングすることにより当サイトもしくはアドテクパートナーからパーソナライズされた広告を表示する、またはソーシャルメディアでシェアする目的でも利用します。
        <br /> <br />
        ※ 当社では、Google
        Analyticsを使用してご利用者様のウェブサイトの利用状況（訪問者数と通信トラフィックソース等）を収集しています。データが収集、処理される仕組みについては、以下のサイトをご参照ください。
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='        https://www.google.com/intl/ja/policies/privacy/partners/ '
          target='_blank'
          rel='noopener noreferrer'
        >
          https://www.google.com/intl/ja/policies/privacy/partners/
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='          https://policies.google.com/privacy?hl=ja '
          target='_blank'
          rel='noopener noreferrer'
        >
          https://policies.google.com/privacy?hl=ja
        </a>
        <br /> <br />※
        当社では、株式会社セールスフォース・ジャパンが提供するPardotを使用してご利用者様のウェブサイトの利用状況（訪問者数と通信トラフィックソース等）を収集しています。株式会社セールスフォース・ジャパンのプライバシーポリシー等については、以下のサイトをご参照ください。
        <a
          className='text-blue-500 hover:text-blue-300'
          href='          https://www.salesforce.com/jp/company/privacy/ '
          target='_blank'
          rel='noopener noreferrer'
        >
          https://www.salesforce.com/jp/company/privacy/
        </a>
        <br />
        <p className='mb-4 mt-4 text-[18px] font-bold'> クッキーの無効化について</p>
        当サイトのご利用者様は、ブラウザの設定を変更することにより、クッキーを無効にすることができます。以下では、一般的なブラウザにおける設定方法を掲載しています。
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='          https://support.google.com/chrome/answer/95647 '
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Google Chrome
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='        https://support.apple.com/ja-jp/HT201265'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Safari (iPhone,iPad)
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='     https://support.apple.com/ja-jp/guide/safari/sfri11471/mac'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Apple Safari (Mac)
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='    https://support.microsoft.com/ja-jp/windows/cookie-%E3%81%AE%E5%89%8A%E9%99%A4%E3%81%A8%E7%AE%A1%E7%90%86%E3%82%92%E8%A1%8C%E3%81%86-168dab11-0753-043d-7c16-ede5947fc64d'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Microsoft Internet Explorer
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='   https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%A7-cookie-%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B-63947406-40ac-c3b8-57b9-2a946a29ae09'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Microsoft Edge
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='  https://support.mozilla.org/ja/kb/cookies-information-websites-store-on-your-computer'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Mozilla Firefox
        </a>
        <br />
        <a
          className='text-blue-500 hover:text-blue-300'
          href='  https://www.criteo.com/jp/privacy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          ・Criteo
        </a>
        <br /> <br />
        なお、クッキーを無効にした場合、当サイトの機能の一部が利用できなくなる、一部のページが正しく表示されなくなる、または当社の一部のサービスが受けられなくなることがありますので、あらかじめご了承ください。
        <br /> <br />
        最終更新日：2023年2月24日
      </div>
    </div>
  );
}

export default CompanyPage;
