import { useNavigate } from "react-router-dom";

import MyProfile from "@/components/layouts/profile/MyProfile";

type CompleteModalProps = {
  setIsCompleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const RegistrationCompleteModal = (props: CompleteModalProps) => {
  const navigate = useNavigate();

  const closeModal = () => {
    props.setIsCompleteModalOpen(false);
    navigate("/profile");
  };
  return (
    <div>
      <div
        className='fixed left-0 top-0 z-40 block h-full w-full cursor-pointer bg-modal-overlay'
        onClick={closeModal}
      />
      <div className='absolute left-1/2 z-50 h-fit -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white pb-8 pt-4 sm:top-[480px] sm:w-[300px] sm:px-4 md:top-1/2 md:w-[662px] md:px-8'>
        <div className='relative'>
          <img
            src='/images/x-mark-blue.svg'
            alt=''
            className='absolute right-0 h-7 w-7 cursor-pointer'
            onClick={closeModal}
          ></img>
        </div>
        <h1 className='mb-[18px] mt-[28px] text-center text-[20px] font-bold sm:mx-auto sm:w-[200px] md:w-fit'>
          プロフィールの登録が完了しました！
        </h1>
        <div className='mb-[18px] w-full '>
          <p className='mb-[18px] whitespace-pre-wrap leading-6 text-black-600 sm:text-left sm:text-[16px] md:text-center md:text-[14px]'>{`お疲れ様でした！
カソークの担当者からのご連絡をお待ちください。`}</p>
          <div className='flex sm:flex-col md:flex-row'>
            <div className='sm:mb-[18px] sm:flex sm:items-center sm:justify-center md:mb-0 md:mr-[18px]'>
              <MyProfile isCompleteModal={true} />
            </div>
            <div className='grid place-items-center sm:w-[268px] md:w-[380px]'>
              <p className=' whitespace-pre-wrap text-[14px] leading-6 text-black-600'>{`①3営業日中・平日9時〜20時以内にお電話にてご連絡いたします。
②面談内で案件をご紹介いたします。
③応募・選考に進んでいただきます。
④選考に合格されましたら、お仕事を開始いただけます。`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCompleteModal;
