import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import HeaderTab from "@/components/layouts/common/HeaderTab";
import LoginModal from "@/components/layouts/login/LoginModal";
import UpperContent from "@/components/layouts/top/UpperContent";
import useMediaQuery from "@/hooks/useMediaQuery";
import useScroll from "@/hooks/useScroll";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { ApiResponse, apiGet, apiPost } from "@/utils/api";

function HeaderPage() {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userSelector);
  const [key, setKey] = useRecoilState(locationState);
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();
  const scroll = useScroll();
  const isSm = useMediaQuery();
  const [isOpenMenue, setIsOpenMenue] = useState(false);
  const [isOpenLoginMenue, setIsOpenLoginMenue] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState<null | boolean>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const colorStyle = {
    background: `linear-gradient(to right, #2c3c6c, #405494)`,
  };
  // 新規登録ページのパス
  const registerPaths = ["/reminder/password", "/reset/password", "/register", "/login"];
  const isRegisterPage = registerPaths.some((path) => {
    if (path === "/register") {
      return location.pathname.startsWith(path);
    } else {
      return location.pathname === path;
    }
  });
  // トップページのパス
  const isTopPath = location.pathname === "/";
  // ログイン不要ページのパス
  const nonLoginPaths = [
    "/reminder/password",
    "/reset/password",
    "/register",
    "/register/sms",
    "/",
    "/jobs",
    "/company",
    "/privacy",
    "/term",
    "/tokutei",
  ];
  const isNonLoginPage = nonLoginPaths.some((path) => {
    if (path.endsWith("/")) {
      return location.pathname === path;
    } else if (path === "/jobs") {
      return location.pathname.startsWith(path);
    } else {
      return location.pathname === path;
    }
  });

  const handleLogin = (isRegisterPage: boolean | null = null) => {
    setIsLoginModal(isRegisterPage);
    setIsOpenLoginMenue((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const handleProfile = () => {
    setIsOpenMenue(false);
    setKey((prevValue) => ({ ...prevValue, path: "/profile", component: "profile" }));
    navigate("/profile");
  };

  const handleLogout = () => {
    apiPost("/api/logout");
    setIsOpenMenue(false);
    setIsOpenLoginMenue(false);
    setIsLoginModal(null);
    setTimeout(() => {
      setIsLogin(false);
      setUser({
        id: null,
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        birthday: "",
        gender: null,
        email: "",
        phone_number: "",
        postal_code: "",
        address: "",
        annual_income: null,
        current_industry: null,
        current_job: null,
        current_company: "",
        register_step: null,
        user_schools: {
          id: null,
          school_type: null,
          school_id: null,
          school_name: "",
          department: "",
          graduated_year: null,
        },
        user_skills: [],
        header_image: "",
      });
      navigate("/login");
    }, 200);
  };

  useEffect(() => {
    const checkLogin = async () => {
      const res: ApiResponse<User> = await apiGet<User>("/api/user/me");
      if ("data" in res && res.success) {
        setIsLogin(true);
        setUser(res.data);
        return;
      }
      if (!isLogin && !isNonLoginPage) {
        setKey((prevValue) => ({ ...prevValue, path: "" }));
        navigate("/login");
      }
    };
    checkLogin();
  }, [navigate]);

  useEffect(() => {
    setIsOpenMenue(false);
  }, [scroll]);

  return (
    <div className='z-50 bg-opacity-30 font-sans'>
      {location.pathname === "/" && (
        <div className='z-[-1]'>
          <UpperContent />
        </div>
      )}
      <div className={`${isTopPath ? "absolute top-[0]" : ""} w-full`}>
        {isLogin && !isRegisterPage ? (
          <nav className='mb-[9px] flex w-full items-center pt-4 sm:h-[56px] md:mb-0 md:h-[80px] md:pl-8'>
            {!isSm ? (
              <button
                className='min-w-[180px]'
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src='/images/logo.svg' alt='logo' className='mr-8 pb-4' />
              </button>
            ) : (
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src='/images/logo-phone.svg' alt='logo' className='mr-4 pb-4' />
              </button>
            )}
            {key.component !== "profile" && key.component !== "detail" && key.component !== "footer" && <HeaderTab />}
            <div className='flex pb-2 pr-8'>
              <div className='flex-grow' />
              <button
                className='text-[14px] font-bold'
                onClick={() => {
                  setIsOpenMenue((prevIsMenuOpen) => !prevIsMenuOpen);
                }}
              >
                <p className='absolute right-8 top-6 md:top-8'>
                  {user.last_name}
                  {" " + user.first_name}
                </p>
              </button>
            </div>
            {isOpenMenue && (
              <div
                ref={menuRef}
                className='fixed z-10 h-fit w-[150px] rounded-md border bg-white p-4 shadow-lg sm:right-[10px] sm:top-[70px] md:right-[40px] md:top-[90px]'
              >
                <ul className='text-[13px] text-gray-600'>
                  <li className='mb-2'>
                    <button onClick={handleProfile} className='hover:text-gray-100'>
                      プロフィール
                    </button>
                  </li>
                  <li className='mb-2'>
                    <a href='/settings' className='hover:text-gray-100'>
                      設定
                    </a>
                  </li>
                  {/* <li className='mb-2'>
                  <a href='#'>ヘルプ</a>
                </li> */}
                  <li className='mb-2'>
                    <button onClick={handleLogout} className='hover:text-gray-100'>
                      ログアウト
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        ) : isRegisterPage ? (
          <nav className='flex items-center pt-4 sm:h-[56px] md:h-[80px] md:pl-8'>
            {location.pathname === "/register" || location.pathname === "/login" ? (
              <button
                className='min-w-[180px]'
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src='/images/logo.svg' alt='logo' className='sm:ml-2 md:ml-0 md:mr-16 md:pb-4' />
              </button>
            ) : (
              <img src='/images/logo.svg' alt='logo' className='sm:ml-2 md:ml-0 md:mr-16 md:pb-4' />
            )}
          </nav>
        ) : (
          <nav className='flex w-full items-center pt-4 sm:h-[56px] md:h-[80px] md:pl-8'>
            <button
              className='w-1/5'
              onClick={() => {
                navigate("/");
              }}
            >
              {isSm ? (
                <img src='/images/logo-phone.svg' alt='logo' className='ml-2 mr-7 pb-2' />
              ) : (
                <img src='/images/logo.svg' alt='logo' className='mr-8 min-w-[180px] pb-5' />
              )}
            </button>
            {isSm && key.component !== "footer" && (
              <div className='ml-4 flex w-2/5 justify-end sm:w-fit'>
                <HeaderTab />
              </div>
            )}
            {!isSm ? (
              <div className='flex w-full items-center justify-end pb-4 pr-8 text-right' id='a'>
                <div className={`${key.component !== "footer" && "sm-min-w-[120px] mt-[16px] min-w-[210px]"}`}>
                  {key.component !== "detail" && key.component !== "footer" && <HeaderTab />}
                </div>
                <a
                  href='https://business.kasooku.jp/'
                  className={`ml-4 flex justify-start ${key.component !== "detail" ? "mr-4 w-[60%]" : "w-[70%]"}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p className='mr-1 pt-1 font-bold hover:text-gray-600'>採用したい企業様はこちら</p>
                  <img src='/images/arrow-top-right.svg' alt='arrow' />
                </a>
                <div className='flex-grow' />
                <a
                  className='ml-2 hidden h-[42px] min-w-[135px] rounded-full border border-gray-200 bg-white px-10 py-3 text-center text-[13px] font-bold text-[#3c447c] hover:text-[#8597cd] focus:outline-none sm:block sm:w-full md:w-fit'
                  href='/login'
                >
                  ログイン
                </a>
                <a
                  className='ml-2 hidden h-[40.5px] min-w-[135px] rounded-full  px-6 py-3 text-center text-[13px] font-bold text-gray-200 hover:text-gray-400 sm:block sm:w-full md:w-fit'
                  href='/register'
                  style={colorStyle}
                >
                  新規会員登録
                </a>
              </div>
            ) : (
              <>
                {!isOpenLoginMenue ? (
                  <div className='mr-2 flex w-1/5 justify-end sm:absolute sm:right-4'>
                    <button
                      style={colorStyle}
                      className='mb-1 ml-2 flex h-[44px] w-[44px] items-center justify-center rounded-md'
                      onClick={() => handleLogin()}
                    >
                      <img src='/images/bars.svg' alt='bars' className='h-5 w-5 ' />
                    </button>
                  </div>
                ) : (
                  <div className='mr-2 flex w-1/5 justify-end sm:absolute sm:right-4'>
                    <button
                      onClick={() => handleLogin()}
                      style={colorStyle}
                      className='mb-1 ml-2 flex h-[44px] w-[44px] items-center justify-center rounded-md'
                    >
                      <img src='/images/x-mark-blue.svg' className='ml-[19px] mr-[18px] h-5 w-5 rounded-lg' alt='' />
                    </button>
                  </div>
                )}
              </>
            )}
            {isOpenLoginMenue && (
              <div
                ref={menuRef}
                className='fixed bottom-0 z-10 rounded-lg border-2 border-[#3c447c] bg-white sm:right-2 sm:top-[70px] sm:h-fit sm:w-1/2 md:right-60 md:top-[120px] md:h-[320px] md:w-[320px] md:p-4 '
              >
                <LoginModal
                  isLoginModal={isLoginModal}
                  setIsLoginModal={setIsLoginModal}
                  setIsOpenLoginMenue={setIsOpenLoginMenue}
                />
              </div>
            )}
          </nav>
        )}
      </div>
    </div>
  );
}

export default HeaderPage;
