import { useState } from "react";

import { Link as Scroll } from "react-scroll";

interface TabProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, active, onClick }) => {
  const activeClasses = active ? "text-blue-700" : "text-gray-500";

  return (
    <div className='inline-block sm:mb-2'>
      <button
        className={`px-4 py-2 font-bold sm:w-[140px] sm:text-sm md:w-full md:text-[16px] ${activeClasses}`}
        onClick={onClick}
      >
        {label}
      </button>
      {active && <div className='mt-2 h-1 bg-blue-700'></div>}
    </div>
  );
};
const TabContainer = (props: { setActiveTab: React.Dispatch<React.SetStateAction<number>> }) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    props.setActiveTab(tab);
  };

  return (
    <div className='mb-4 mt-16 flex justify-start sm:overflow-x-scroll md:overflow-x-hidden'>
      <Scroll to='1'>
        <Tab label='働く条件' active={activeTab === 1} onClick={() => handleTabClick(1)} />
      </Scroll>
      <Scroll to='2'>
        <Tab label='求めるスキル・経験' active={activeTab === 2} onClick={() => handleTabClick(2)} />
      </Scroll>
      <Scroll to='3'>
        <Tab label='業務内容' active={activeTab === 3} onClick={() => handleTabClick(3)} />
      </Scroll>
      <Scroll to='4'>
        <Tab label='事業紹介' active={activeTab === 4} onClick={() => handleTabClick(4)} />
      </Scroll>
      <Scroll to='5'>
        <Tab label='会社情報' active={activeTab === 5} onClick={() => handleTabClick(5)} />
      </Scroll>
    </div>
  );
};

export default TabContainer;
