import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import "react-toastify/dist/ReactToastify.css";

import FooterLayout from "@/components/layouts/common/Footerlayout";
import HeaderLayout from "@/components/layouts/common/HeaderLayout";
import CompanyPage from "@/components/pages/footer/CompanyPage";
import PrivacyPage from "@/components/pages/footer/PrivacyPage";
import TermPage from "@/components/pages/footer/TermPage";
import TokuteiPage from "@/components/pages/footer/TokuteiPage";
import JobsPage from "@/components/pages/jobs/JpbsPage";
import LoginPage from "@/components/pages/login/LoginPage";
import ReminderPasswordPage from "@/components/pages/login/ReminderPasswordPage";
import ResetPasswordPage from "@/components/pages/login/ResetPasswordPage";
import ProfilePage from "@/components/pages/profile/ProfilePage";
import AnnualIncomePage from "@/components/pages/Register/AnnualIncomePage";
import BirthdayPage from "@/components/pages/Register/BirthdayPage";
import CurrentCompanyPage from "@/components/pages/Register/CurrentCompanyPage";
import CurrentIndustryPage from "@/components/pages/Register/CurrentIndustryPage";
import CurrentJobPage from "@/components/pages/Register/CurrentJobPage";
import EmailPage from "@/components/pages/Register/EmailPage";
import GenderPage from "@/components/pages/Register/GenderPage";
import LastEducationPage from "@/components/pages/Register/LastEducationPage";
import PasswordPage from "@/components/pages/Register/PasswordPage";
import PostalCodePage from "@/components/pages/Register/PostalCodePage";
import RegisterPage from "@/components/pages/Register/RegisterPage";
import SmsPage from "@/components/pages/Register/SmsPage";
import TopPage from "@/components/pages/TopPage";

import "@/App.css";

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <ToastContainer />
        <HeaderLayout></HeaderLayout>
        <Routes>
          <Route path='/' Component={TopPage} />
          <Route path='/login' Component={LoginPage} />
          <Route path='/reminder/password' Component={ReminderPasswordPage} />
          <Route path='/reset/password' Component={ResetPasswordPage} />
          <Route path='/register/*' Component={RegisterPage} />
          <Route path='/register/sms' Component={SmsPage} />
          <Route path='/register/email' Component={EmailPage} />
          <Route path='/register/password' Component={PasswordPage} />
          <Route path='/register/gender' Component={GenderPage} />
          <Route path='/register/birthday' Component={BirthdayPage} />
          <Route path='/register/postal-code' Component={PostalCodePage} />
          <Route path='/register/annual-income' Component={AnnualIncomePage} />
          <Route path='/register/last-education' Component={LastEducationPage} />
          <Route path='/register/current-industry' Component={CurrentIndustryPage} />
          <Route path='/register/current-job' Component={CurrentJobPage} />
          <Route path='/register/current-company' Component={CurrentCompanyPage} />
          <Route path='/profile/' Component={ProfilePage} />
          <Route path='/Jobs/:id' Component={JobsPage} />
          <Route path='/company/' Component={CompanyPage} />
          <Route path='/term/' Component={TermPage} />
          <Route path='/privacy/' Component={PrivacyPage} />
          <Route path='/tokutei/' Component={TokuteiPage} />
        </Routes>
        <FooterLayout></FooterLayout>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
